import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { motion } from "framer-motion"
import Nav from "../components/nav"
import { Toggle } from "./toggle"

const Header = ({ siteTitle }) => (
  <motion.div
    className="opacity-0 content"
    animate={{ x: 0, y: 0, opacity: 1 }}
    transition={{
      delay: 1.6,
      x: { type: "spring", stiffness: 100 },
      default: { duration: 0.6 },
    }}
  >
    <header className="py-4 md:pb-16 pt-8">
      <div className="lg:container lg:mx-auto flex px-16">
        <div className="flex flex-auto flex-wrap lg:flex-col">
          <Link className="flex" to="/">
            <div className="halo branding">
              <div className="dark spinning-rings">
                <div className="spinning logo started">
                  <div className="rings">
                    <div className="tilt one">
                      <span className="ring one">
                        <span className="texture"></span>
                      </span>
                    </div>
                    <div className="tilt two">
                      <span className="ring two">
                        <span className="texture"></span>
                      </span>
                    </div>
                    <div className="tilt three">
                      <span className="ring three">
                        <span className="texture"></span>
                      </span>
                    </div>
                    <div className="tilt four">
                      <span className="ring four">
                        <span className="texture"></span>
                      </span>
                    </div>
                  </div>
                  <svg>
                    <path
                      className="z-shape"
                      stroke=""
                      fill=""
                      d="M15.2,36.2c0,0.7,0.6,1.5,2.1,1c1.2,3-3.9,3.7-3.9,0.7c0-1.6,1.3-2.6,3.2-3.4L34,21.3 c-5.9,1.8-16,0.8-16,4.1c0,1.5,2.2,1.9,3.6,1.9v0.1c-4.3,0.1-5.6-3.1-5.6-5.4c0-3.1,1.9-6,6-6c4.8,0,9.9,4.7,12.7,4.7 c0.9,0,2.1-0.3,2.1-1.5c0-0.9-1.1-1.6-2.1-1.2c-0.8-1.2,0.2-2.6,1.6-2.6c1,0,2.1,0.6,2.1,2c0,1.8-1.4,2.9-3.4,3.6L17.5,34.1 c6-2.3,16.7-2,16.7-4.7c0-1.1-2.6-1.3-3.8-1.2v-0.1c4.3-0.4,5.8,1.9,5.8,4.5c0,4-3.4,6.6-6.8,6.6c-4.5,0-9.1-4.7-11.9-4.7 C16.6,34.5,15.2,34.9,15.2,36.2z M30.4,11.5c0,1.5-1.3,2.6-2.7,2.6c-1.1,0-1.9-0.8-1.9-2c0-1.4,1.3-2.6,2.7-2.6 C29.6,9.5,30.4,10.4,30.4,11.5z"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
            <span className="font-normal text-gray-500 mt-5 ml-4 hidden md:inline-block">
              {siteTitle}
            </span>
          </Link>
        </div>

        <Nav />
        <Toggle />
      </div>
    </header>
  </motion.div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
