import React from "react"
import { ThemeContext } from "./themeContext"

export const Toggle = () => {
  const { theme, setTheme } = React.useContext(ThemeContext)

  function isDark() {
    return theme === "dark"
  }

  return (
    <>
      <label className="text-primary toggle-btn toggler mr-20 md:mr-0">
        <input
          type="checkbox"
          checked={isDark()}
          onChange={e => setTheme(e.target.checked ? "dark" : "light")}
        ></input>
        <span></span>
      </label>
    </>
  )
}
